const ButtonCode = {
  BTN_HEADER_HOME: 'BTN_HEADER_HOME', // 헤더 홈 버튼
  BTN_MYPAGE: 'BTN_MYPAGE', // 헤더
  BTN_HEADER_BUY: 'BTN_HEADER_BUY', // 헤더 구매하기 버튼
  BTN_HEADER_SELL: 'BTN_HEADER_SELL', // 헤더 판매하기 버튼
  BTN_HEADER_COMPANY_ABOUT: 'BTN_HEADER_COMPANY_ABOUT', // 소개자료 버튼
  BTN_HEADER_COMPANY_CAREERS: 'BTN_HEADER_COMPANY_CAREERS', // 인재채용 버튼
  BTN_HEADER_BLOG: 'BTN_HEADER_BLOG', // 블로그 버튼
  BTN_HEADER_FACEBOOK: 'BTN_HEADER_FACEBOOK', // 페이스북 버튼
  BTN_HEADER_LINKEDIN: 'BTN_HEADER_LINKEDIN', // 링크드인 버튼
  BTN_HEADER_MEDIUM: 'BTN_HEADER_MEDIUM', // 미디엄 버튼
  BTN_HEADER_SUPPORT_CHAT: 'BTN_HEADER_SUPPORT_CHAT', // 실시간 문의 버튼
  BTN_HEADER_SUPPORT_STANDARD_QUOTE: 'BTN_HEADER_SUPPORT_STANDARD_QUOTE', // 표준양식서 버튼
  BTN_HEADER_SUPPORT_FAQ: 'BTN_HEADER_SUPPORT_FAQ', // FAQ 버튼
  BTN_FOOTER_BUY: 'BTN_FOOTER_BUY', // 헤더 구매하기 버튼
  BTN_FOOTER_SELL: 'BTN_FOOTER_SELL', // 헤더 판매하기 버튼
  BTN_FOOTER_COMPANY_ABOUT: 'BTN_FOOTER_COMPANY_ABOUT', // 소개자료 버튼
  BTN_FOOTER_COMPANY_CAREERS: 'BTN_FOOTER_COMPANY_CAREERS', // 인재채용 버튼
  BTN_FOOTER_BLOG: 'BTN_FOOTER_BLOG', // 블로그 버튼
  BTN_FOOTER_FACEBOOK: 'BTN_FOOTER_FACEBOOK', // 페이스북 버튼
  BTN_FOOTER_LINKEDIN: 'BTN_FOOTER_LINKEDIN', // 링크드인 버튼
  BTN_FOOTER_MEDIUM: 'BTN_FOOTER_MEDIUM', // 미디엄 버튼
  BTN_FOOTER_SUPPORT_CHAT: 'BTN_FOOTER_SUPPORT_CHAT', // 실시간 문의 버튼
  BTN_FOOTER_SUPPORT_STANDARD_QUOTE: 'BTN_FOOTER_SUPPORT_STANDARD_QUOTE', // 표준양식서 버튼
  BTN_FOOTER_SUPPORT_FAQ: 'BTN_FOOTER_SUPPORT_FAQ', // FAQ 버튼
  BTN_FOOTER_EMAIL: 'BTN_FOOTER_EMAIL', // 이메일 버튼
  BTN_WRITE: 'BTN_WRITE', // 작성 버튼
  BTN_CHECK: 'BTN_CHECK', // 확인 버튼
  BTN_HOME: 'BTN_HOME', // 홈 버튼
  BTN_NEXT: 'BTN_NEXT', // 다음 버튼
  BTN_BACK: 'BTN_BACK', // 뒤로가기 버튼
  BTN_LOGIN: 'BTN_LOGIN', // 로그인 버튼
  BTN_LOGOUT: 'BTN_LOGOUT', // 로그아웃 버튼
  BTN_JOIN: 'BTN_JOIN', // 회원가입 버튼
  BTN_FIND_USER: 'BTN_FIND_USER', // 회원정보 찾기 버튼
  BTN_SAVE: 'BTN_SAVE', // 저장하기 버튼
  BTN_HERE: 'BTN_HERE', // 여기 버튼
  BTN_WAREHOUSE: 'BTN_WAREHOUSE', // 장바구니 버튼
  BTN_CART: 'BTN_CART', // 장바구니 버튼
  BTN_MY_STOCK: 'BTN_MY_STOCK', // 개인창고 버튼
  BTN_SCROLL: 'BTN_SCROLL', // 아래 스크롤 버튼
  BTN_BUY: 'BTN_BUY', // 구매하기 버튼
  BTN_SELL: 'BTN_SELL', // 판매하기 버튼
  BTN_SELL_DETAIL: 'BTN_SELL_DETAIL', // 판매하기 상세 버튼
  BTN_CHANNEL_TALK: 'BTN_CHANNEL_TALK', // 문의하기 버튼
  BTN_DELETE: 'BTN_DELETE', // 삭제하기 버튼
  BTN_CANCEL: 'BTN_CANCEL', // 취소하기 버튼
  BTN_SERVICE: 'BTN_SERVICE', // 시작하기 버튼
  BTN_FILE_DOWNLOAD: 'BTN_FILE_DOWNLOAD', // 파일 다운로드 버튼
  BTN_BUY_SEARCH: 'BTN_BUY_SEARCH', // 검색하기 버튼
  BTN_STOCK_SEARCH: 'BTN_STOCK_SEARCH', // 재고 등록 버튼
  BTN_MY_STOCK_ENTER: 'BTN_MY_STOCK_ENTER', // 나의 창고 입장
  BTN_OPEN_STOCK_ENTER: 'BTN_OPEN_STOCK_ENTER', // 열린 창고 입장
  BTN_BUY_FILE_UPLOAD: 'BTN_BUY_FILE_UPLOAD', // 파일 등록하기 버튼
  BTN_COMPANY: 'BTN_COMPANY', // 회사소개 바로가기 버튼
  BTN_HEADER_PRODUCT_MANAGE: 'BTN_HEADER_PRODUCT_MANAGE', // 프로젝트 관리 버튼
  BTN_CART_ADD: 'BTN_CART_ADD', // 장바구니에 담기 버튼
  BTN_PRODUCT_READ: 'BTN_PRODUCT_READ', // 자재 확인하기 버튼
  BTN_SUBMIT: 'BTN_SUBMIT', // Form 제출하기 버튼
  BTN_CERTIFICATION: 'BTN_CERTIFICATION', // 인증하기 버튼
  BTN_EMAIL_CHECK: 'BTN_EMAIL_CHECK', // 이메일 중복확인 버튼
  BTN_MODAL_CONFIRM: 'BTN_MODAL_CONFIRM', // Modal Confirm 버튼
  BTN_NOTI: 'BTN_NOTI', // 알림 버튼
  BTN_SERVICE_TERMS: 'BTN_SERVICE_TERMS', // 서비스 이용약관
  BTN_POLICY: 'BTN_POLICY', // 개인정보처리방침
  BTN_MARKETING: 'BTN_MARKETING', // 마케팅 수신동의
  BTN_ADDITIONAL_REQUEST_FILE_DOWNLOAD: 'BTN_ADDITIONAL_REQUEST_FILE_DOWNLOAD', // 추가 요청사항 파일 다운로드 버튼
  BTN_ADDITIONAL_APPROVED_VENDOR_LIST_FILE_DOWNLOAD: 'BTN_ADDITIONAL_APPROVED_VENDOR_LIST_FILE_DOWNLOAD', // 승인 공급 업체 리스트 파일 다운로드 버튼
  BTN_ALL_PROJECT: 'BTN_ALL_PROJECT', // 전체 프로젝트
  BTN_IN_PROGRESS_PROJECT: 'BTN_IN_PROGRESS_PROJECT', // 진행중인 프로젝트 버튼
  BTN_CLOSED_PROJECT: 'BTN_CLOSED_PROJECT', // 완료된 프로젝트 버튼
  BTN_PRODUCT_NOTI: 'BTN_PRODUCT_NOTI', // 자재별 알림 신청 버튼
  BTN_NOTI_SETTING_EMAIL: 'BTN_NOTI_SETTING_EMAIL', // 이메일로 알림 받기 버튼
  BTN_NOTI_SETTING_KAKAO: 'BTN_NOTI_SETTING_KAKAO', // 카카오톡으로 알림 받기 버튼
  BTN_NOTI_SETTING_MARKETING: 'BTN_NOTI_SETTING_MARKETING', // 마케팅 정보 수신 동의 버튼
  BTN_SHORT_OPTION: 'BTN_SHORT_OPTION', // 간략히 보기 버튼
  BTN_FULL_OPTION: 'BTN_FULL_OPTION', // 전체 사양 보기 버튼
  BTN_COMPANY_TOP_FILE_DOWNLOAD: 'BTN_COMPANY_TOP_FILE_DOWNLOAD', // 회사소개서 다운로드 버튼(TOP),
  BTN_COMPANY_BOTTOM_FILE_DOWNLOAD: 'BTN_COMPANY_BOTTOM_FILE_DOWNLOAD', // 회사소개서 다운로드 버튼(BOTTOM),
  BTN_LANGUAGE_KO: 'BTN_LANGUAGE_KO', // 언어 변경 버튼(KO)
  BTN_LANGUAGE_EN: 'BTN_LANGUAGE_EN', // 언어 변경 버튼(EN)
  BTN_EDIT_PASSWORD: 'BTN_EDIT_PASSWORD', // 패스워드 변경 버튼,
  BTN_EDIT_COMPANY_REGISTRATION_NUMBER: 'BTN_EDIT_COMPANY_REGISTRATION_NUMBER', // 사업자 정보 변경 버튼
  BTN_EDIT_NAME: 'BTN_EDIT_NAME', // 이름 변경 버튼
  BTN_EDIT_PHONE: 'BTN_EDIT_PHONE', // 연락처 변경 버튼
  BTN_EDIT_COMPANY_DEPARTMENT: 'BTN_EDIT_COMPANY_DEPARTMENT', // 소속 부서 변경 버튼
  BTN_EDIT_COMPANY_TITLE: 'BTN_EDIT_COMPANY_TITLE', // "직함 변경 버튼
  BTN_EDIT_MATERIAL_ALARM: 'BTN_EDIT_MATERIAL_ALARM', // 자재별 알림 변경 버튼
  BTN_SIGNOUT: 'BTN_SIGNOUT', // 회원탈퇴 버튼
  BTN_NOTI_ICON: 'BTN_NOTI_ICON', // 알림 아이콘 버튼
  BTN_USER_ICON: 'BTN_USER_ICON', //유저 아이콘 버튼,
  BTN_LANGUAGE_ICON: 'BTN_LANGUAGE_ICON', // 언어 아이콘 버튼
  BTN_EXCEL_DOWNLOAD_UPLOAD: 'BTN_EXCEL_DOWNLOAD_UPLOAD', // 엑셀 다운로드/업로드 버튼
  BTN_EXCEL_DOWNLOAD: 'BTN_EXCEL_DOWNLOAD', // 엑셀 다운로드
  BTN_NOT_SEE_1_MONTH: 'BTN_NOT_SEE_1_MONTH', // 1달동안 보지 않기
  BANNER_BTN_PRODUCT_NOTI: 'BANNER_BTN_PRODUCT_NOTI', // 배너 알림 설정 버튼
} as const;

export default ButtonCode;
